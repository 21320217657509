.wallpaper-card {
   display: inline-flex;
   overflow: hidden;
   flex-direction: column;
   height: fit-content;
   margin-top: 8px;
   width: 100%;

   font-size: var(--sub-header);
   font-weight: 800;
   text-decoration: none;

   color: var(--white);
   background-color: var(--darker-black);

   border: 2px solid var(--dark-black);
   border-radius: var(--border-radius);

   transition: var(--transition-all);

   @media (max-width: 400px) {
      width: 100%;
      padding: 0;
      min-width: 0px;
   }

   img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
      object-fit: center center;

      @media (max-width: 400px) {
         width: 100%;
         padding: 0;
      }
      
   }

   div {
      padding: 8px;
      -webkit-text-stroke: 0.5px var(--black);
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
         font-size: var(--sub-header);
         font-weight: 800;
         margin: 0;
      }
      p {
         margin: 0;
         font-size: var(--paragraph);
      }
   }
}

.wallpaper-card:hover {
   color: var(--white);
   border: 2px solid var(--orange);
   cursor: pointer;
}