#giveaways {
   width: 100%;
   display: flex;
   flex-direction: row;
   gap: 8px;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   h3 {
      font-size: var(--header);
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: var(--super-bold);
   }

   .giveaway {
      border-radius: var(--border-radius);
      padding: 16px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--black);
      .giveaway-button {
         font-size: var(--paragraph);
      }
      border-bottom: 1px solid var(--black);
   }
   .giveaway-container {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
   }
}