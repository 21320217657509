#deskmats {
   padding: 16px;
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   background-color: var(--black);
   border-radius: var(--border-radius);
   h3 {
      font-size: var(--header);
      height: 30px;
   }
}

.deskmats-container {
   width: 100%;
   display: grid;
   gap: 4px 8px;
   grid-template-columns: auto auto;
   grid-template-rows: auto auto;
   justify-content: space-evenly;

   @media (max-width: 1090px) {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      justify-content: space-evenly;
   }
   @media (max-width: 500px) {
      grid-template-columns: 100%;
      padding: 0;
      min-width: 0;
   }
}

.ReactModal__Overlay {
   background-color: var(--dark-transparent) !important;
   z-index: 10;
}

#deskmat-modal {
   padding: 0 !important;
   border: 2px solid var(--black) !important;
}
.ReactModal__Content::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
}


.ReactModal__Content {
   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */

   width: 85vw;
   height: 80vh;
   min-width: 500px;
   min-height: 500px;
   max-width: 1400px;

   overflow: auto;
   padding: 0 8px 8px 8px !important;
   transition: var(--transition-all);

   @media (max-width: 700px) {
      min-width: 200px;
      min-height: 200px;
      width: 95vw;
      height: 88vh;
   }

      
   .deskmat-modal-header {
      position: sticky !important;
      top: -1px !important;
      left: 0 !important;
      background: var(--black) !important;
      padding: 8px;
      z-index: 2;
      flex-direction: row;
      align-items: center;

      h2 {
         margin: 0;
         display: flex;
         align-items: center;
         @media (max-width: 500px) {
            font-size: 18px;
         }
      }

      
      .modal-close-container {
         position: relative;
         
         .FaTimes {
            font-size: 20px;
            cursor: pointer;
            transition: var(--transition-all);
            position: absolute;
            top: -10px;
            right: 4px;
         }
         .FaTimes:hover {
            color: var(--orange) !important;
         }
      }

      
      #deskmat-container {
         width: 50%;
         height: 35px;
         @media (max-width: 500px) {
            width: 100px;
         }
         @media (max-width: 500px) {
            width: 100px;
         }
         @media (max-width: 480px) {
            width: 150px;
         }
         border-radius: var(--border-radius);
         border: var(--border);
         padding: 4px;
         font-size:var(--paragraph);
         background-color: var(--dark-black);
         display: flex;
         align-items: center;
         justify-content: center;

         input {
            outline: none;
            background-color: var(--dark-black);
            color: var(--white);
            height: 100%;
            width: 100%;
            border-radius: var(--border-radius);
            border: var(--border);
            transform: translateZ(0);
         }
      }
      
   }
}


#deskmat-search-container {
   gap: 16px;
}