.social_icon {
   display:flex;
   justify-content: center;
   align-items: center;
   width:fit-content;
   height: fit-content;
   color: var(--white);
   font-size: var(--icon);
   @media (max-width: 380px) {
      font-size: var(--mobile-icon);
   }
   font-weight:600;
   padding: 4px;
   border-radius:var(--border-radius);
   background-color: transparent;

   transition: var(--transition-all);
}

.social_icon:hover {
   color: var(--orange);
   background-color: var(--gray);
}