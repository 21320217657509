#header {
   z-index: 2;
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: column; 
   align-items: center;
   position: sticky;
   top: -8px;
   left: 0;
   @media (max-width: 400px) {
      top: -4px;
      left: 0;
   }
   @media (max-height: 500px) {
      position: relative;
   }
   
   .blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
   }

   #top-layer-container {
      width: 100%;
      max-width: 1400px;
      background-color: var(--darker-black);
      background-position: center center;
      background-size: cover;
      border-radius: var(--border-radius);
      border: solid 2px var(--dark-black);
      animation: var(--pulseBorderOrange);
      box-shadow: var(--box-shadow);
         
      #header-top-layer {
         background-color: var(--darker-black);
         width: 100%;
         padding: 0 0.5rem;
         z-index: 2;
         border-radius: var(--border-radius);
         
         display: flex;
         flex-direction: row;
         align-items:center;
         
         @media (max-width: 370px) {
            flex-direction: column;
            justify-content: center;

            .social_container {
               justify-content: center !important;
               margin: 0 0 16px 0;
            }
         }
         
         #header-top-half {
            height: 4.625rem;
            display: flex;
            align-items: center;
            flex-direction: row;
            
            h3 {
               height: 1.563rem;
               font-size: var(--sub-header);
               margin: 0;
            }
            #mail-to  {
               text-decoration: none;
               color: var(--white);
               transition: var(--transition-all);
               display: flex;
               flex-direction: row;
               align-items: center;
            }
            #mail-to:hover {
               color: var(--orange);
            }
            #profile-pic {
               margin: 0;
               height: 3.125rem;
               width: 3.125rem;
               border-radius: var(--border-radius);
               background-image: url('../imgs/ST-Watermark.png');
               background-size: cover;
               box-shadow: var(--box-shadow);

            }
            
         }
         
         .social_container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            height: fit-content;
         }
      }
   }
   
}


