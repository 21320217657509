#footer {
   width: 100%;
   height: fit-content;
   display: flex;
   flex-direction: column; 
   align-items: center;
   position: relative;
   text-decoration: none;
   color: var(--gray);
   margin-bottom: 16px;
   

   #top-layer-container {
      width: 100%;
      max-width: 1400px;
      background-color: var(--darker-black);
      background-image: url('../imgs/SULCETECH-Header-clean.png');

      background-position: center center;
      background-size: cover;
      border-radius: var(--border-radius);
      border: solid 2px var(--dark-black);
      animation: var(--pulseBorderOrange);
      box-shadow: var(--box-shadow);

      transition: var(--transition-all);
         
      #footer-top-layer {
         
         width: 100%;
         padding: 0.5rem;
         z-index: 2;
         border-radius: var(--border-radius);
         background-color: var(--darker-black);
         backdrop-filter: blur(1px) brightness(10%);
         
         display: flex;
         flex-direction: row;
         align-items:center;

         @media (max-width: 550px) {
            flex-direction: column;
         }
         
         #footer-top-half {
            height: 2.625rem;
            h3 {
               height: 1.563rem;
               font-size: var(--paragraph);
               margin: 0;
               width: 50%;
            }
         }
         
         .social_container, #footer-qoute {
            width: 100%;
            color: var(--darker-white);
         }

         #footer-qoute {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            text-decoration: none !important;
         }
         
         #footer-qoute h3{
            font-size: var(--sub-header) !important;
            margin: 0;
            width: fit-content;
            
            
            transition: var(--transition-all);
            @media (max-width: 400px) {
               font-size: 14px !important;
            }
         }
         #footer-qoute h3:hover {
            color: var(--orange);
         }
      }
   }
   
}


.separate_icons {
   margin: 0 4px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: var(--white);
   text-decoration: none;
   transition: var(--transition-all);

   
   p {
      margin: 0;
   } 
}
.separate_icons:hover {
      color: var(--orange) !important;
      .social_icon {
         color: var(--orange);
         background-color: var(--gray);
      }
}


.seperate_icons_container {
   display: flex;
   flex-direction: row;
   align-items: center;
   width: fit-content !important;
}