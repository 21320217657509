#buy-me-a-coffee {
   width: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 8px;
   cursor: pointer;
   background-color: var(--orange);
   border-radius: var(--border-radius);
   flex-grow: 1;
}