.product-card {
   display: inline-flex;
   overflow: hidden;
   height: 100px;
   margin-top: 8px;
   width: 100%;
   position: relative;


   font-size: var(--sub-header);
   font-weight: 800;
   text-decoration: none;

   color: var(--white);
   background-color: var(--darker-black);

   border: 2px solid var(--dark-black);
   border-radius: var(--border-radius);

   transition: var(--transition-all);
   @media (max-width: 400px) {
      width: 100%;
      padding: 0;
      min-width: 0px;
   }

   img {
      aspect-ratio: 1/1;
      height: 100px;
      width: 100px;
      object-fit: cover;
   }

   
   .product-card:hover  {
      color: var(--orange);
      border: 2px solid var(--orange);
   }
}

.product-link {
   width: 100%;
   text-decoration: none;
   padding: 8px;
   -webkit-text-stroke: 0.5px var(--black);
   cursor: pointer;
   h4 {
      transition: var(--transition-all);
      font-size: var(--sub-header);
      font-weight: 600 !important;
      margin: 0;
      white-space: nowrap;
      color: var(--white);
   }
   p {
      transition: var(--transition-all);
      margin: 0;
      font-size: var(--paragraph);
      overflow: hidden;
      height: 46px;
      color: var(--darker-white);
      width: 75%;
      text-wrap: wrap;
   }
}

.product-link:hover {
   h4 {
      color: var(--orange);
   }
   p {
      color: var(--orange);
   }
}

#product-icons {
   position: absolute;
   top: 0;
   right: 8px;
   transition: var(--transition-all);
   display: flex;
}

.product-icon {
   cursor: pointer;
   padding: 0 4px;
   backdrop-filter: blur(5px);
   height: 30px;
   .FaAmazon, .SiAliexpress, .FaVideo {
      font-size: var(--icon);
      color: var(--darker-white);
   }
}

.product-icon:hover, .product-icon:hover .FaVideo {
   color: var(--orange);
}

.product-card:hover  {
   color: var(--orange);
   border: 2px solid var(--black);
}

#preview-video-iframe {
   aspect-ratio: 16/9;
   border-radius: var(--border-radius);
   width: 1400px;

   @media (max-width: 1400px) {
      width: 850px;
   }

   @media (max-width: 820px) {
      width: 100%;
   }
}

#preview-modal {
   height: fit-content;
   padding: 0 !important;
}
