.external_link {
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   width: fit-content;
   height: fit-content;

   font-size: var(--paragraph);
   font-weight: 800;
   text-decoration: none;
   color: var(--white);
   background-color: var(--darker-black);
    
   padding: 0 16px;
   border: 2px solid var(--orange);
   border-radius: var(--border-radius);

   transition: var(--transition-all);
   flex-wrap: wrap;
}

.external_link:hover {
   color: var(--white);
   background-color: var(--orange);
   cursor: pointer;
}
