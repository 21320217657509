#affiliates {
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   h3 {
      font-size: var(--header);
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: var(--super-bold);
   }

   .affiliates-container {
      width: 100%;
      display: grid;
      gap: 8px;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: fit-content fit-content;
      justify-content: space-evenly;

      @media (max-width: 780px) {
         grid-template-columns: 50% 50%;
         grid-template-rows: fit-content fit-content;
         justify-content: space-evenly;
      }
      @media (max-width: 400px) {
         grid-template-columns: 100%;
         width: 100%;
         padding: 0;
         min-width: 0;
      }
   }
   .view-all-affiliates {
      @media (max-width: 400px) {
         padding: 0 8px 16px 8px;
      }
   }
}


.ReactModal__Overlay {
   background-color: var(--dark-transparent) !important;
   z-index: 10;
   backdrop-filter: blur(5px);
}

.affiliate-container {
   width: 50%;
   height: 35px;
   @media (max-width: 300px) {
      width: 50px;
   }
   @media (max-width: 400px) {
      width: 100px;
   }
   @media (max-width: 480px) {
      width: 150px;
   }
   border-radius: var(--border-radius);
   border: var(--border);
   padding: 4px;
   font-size:var(--paragraph);
   background-color: var(--dark-black);
   display: flex;
   align-items: center;
   justify-content: center;

   input {
      outline: none;
      background-color: var(--dark-black);
      color: var(--white);
      height: 100%;
      width: 100%;
      border-radius: var(--border-radius);
      border: var(--border);
      transform: translateZ(0);
   }
}

#short-affiliate-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   max-width: 700px;
   height: fit-content;
   margin-bottom: 8px;
   
   border-radius: var(--border-radius);
   border: var(--border);
   padding: 4px 8px;
   font-size:var(--paragraph);
   .affiliate-container {
      width: 100%;
      max-width: 500px;
   }
}

#affiliate-search-container {
   gap: 16px;
}