
#latest-video-iframe {
   aspect-ratio: 16/9;
   border-radius: var(--border-radius);
   width: 850px;

   @media (max-width: 1400px) {
      width: 750px;
   }

   @media (max-width: 820px) {
      width: 600px;
   }
   
   @media (max-width: 670px) {
      width: 480px;
   }
   @media (max-width: 550px) {
      width: 370px;
   }
   @media (max-width: 440px) {
      width: 100%;
   }
   @media (max-width: 400px) {
      width: 100%;
   }
}

