#products {
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   h3 {
      font-size: var(--header);
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: var(--super-bold);
   }

   .products-container {
      width: 100%;
      display: grid;
      gap: 8px;
      grid-template-columns: 50% 50%;
      grid-template-rows: fit-content fit-content;
      justify-content: space-evenly;
      padding: 0 8px 16px 8px;

      @media (max-width: 780px) {
         grid-template-columns: 100%;
         grid-template-rows: fit-content fit-content;
         justify-content: space-evenly;
      }
      @media (max-width: 400px) {
         grid-template-columns: 100%;
         width: 100%;
         padding: 0;
         min-width: 0;
      }
   }
   .view-all-products {
      @media (max-width: 400px) {
         padding: 0 8px 16px 8px;
      }
   }
}



.modal-products-container {
   width: 100%;
   display: grid;
   gap: 4px 8px;
   grid-template-columns: 50% 50%;
   grid-template-rows: fit-content fit-content;
   justify-content: space-evenly;
   padding: 0 8px 16px 8px !important;

   @media (max-width: 1090px) {
      grid-template-columns: 100%;
      grid-template-rows: fit-content fit-content;
      justify-content: space-evenly;
   }
   @media (max-width: 400px) {
      grid-template-columns: 100%;
      padding: 0;
      min-width: 0;
   }
}

.ReactModal__Overlay {
   background-color: var(--dark-transparent) !important;
   z-index: 10;
   backdrop-filter: blur(5px);
}

#products-modal {
   padding: 0 !important;
   border: 2px solid var(--black) !important;
}
.ReactModal__Content::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
}

.ReactModal__Content {
   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */

   width: 80vw;
   height: 85vh;
   min-width: 500px;
   min-height: 500px;
   max-width: 1400px;

   overflow: auto;
   padding: 0px !important;
   border: none;
   transition: var(--transition-all);

   .product-modal-header {
      position: sticky !important;
      top: -1px !important;
      left: 0 !important;
      background: var(--black) !important;
      padding: 8px;
      z-index: 2;
      width: 100%;
      display: flex;
      flex-direction: column;

     
      #product-header-container {
         display: flex;
         justify-content: space-between;
         margin: 0 0 4px 0;
         padding: 0;
         width: 100% !important;
         height: 100%;
         position: relative;
        
         h2 {
            margin: 0;
            display: flex;
            align-items: center;
            @media (max-width: 400px) {
               font-size: 18px;
            }
         }
         .modal-close-container {
            position: relative;

            .FaTimes {
               font-size: 20px;
               cursor: pointer;
               transition: var(--transition-all);
               position: absolute;
               top: 4px;
               right: 4px;
            }
            .FaTimes:hover {
               color: var(--orange) !important;
            }
         }

      }
   }
   #products-filters {
      display: flex;
      width: 100%;
      ul{
         display: flex;
         flex-direction: row;
         width: 100%;
         overflow: hidden;
         margin: 0;
         padding: 0;
         flex-wrap: wrap;
         li {
            list-style-type: none;
            width:  fit-content;
            padding: 0 8px;
            margin: 8px 4px 0px 4px;

            height: 28px !important;
            font-weight: 800;
            font-size: var(--paragraph);

            color: var(--white);
            transition: var(--transition-all);
            cursor: pointer;
            border: 2px solid var(--dark-black);
            border-radius: var(--border-radius)
         }
         li:hover {
            color: var(--black);
            border: 2px solid var(--orange);
            background-color: var(--orange);
         }
         .product-filter-selected {
            color: var(--white) !important;
            transition: var(--transition-all);
            cursor: pointer;
            border: 2px solid var(--orange);
            padding: 0 8px;
            border-radius: var(--border-radius)
         }
      } 
   }
}


.product-container {
   width: 50%;
   height: 35px;
   @media (max-width: 300px) {
      width: 50px;
   }
   @media (max-width: 400px) {
      width: 100px;
   }
   @media (max-width: 480px) {
      width: 150px;
   }
   border-radius: var(--border-radius);
   border: var(--border);
   padding: 4px;
   font-size:var(--paragraph);
   background-color: var(--dark-black);
   display: flex;
   align-items: center;
   justify-content: center;

   input {
      outline: none;
      background-color: var(--dark-black);
      color: var(--white);
      height: 100%;
      width: 100%;
      border-radius: var(--border-radius);
      border: var(--border);
      transform: translateZ(0);
   }
}

#short-product-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   max-width: 700px;
   height: fit-content;
   margin-bottom: 8px;
   
   border-radius: var(--border-radius);
   border: var(--border);
   padding: 4px 8px;
   font-size:var(--paragraph);
   .product-container {
      width: 100%;
      max-width: 500px;
   }
}

#product-search-container {
   gap: 16px;
}